import React from 'react'

function NotFount(){
        return(
            <React.Fragment>
               <div className="content-wrapper">
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>404 Not Fount</h1>
                    </div>
                    </div>
                </div>
                </section>
            </div>
            </React.Fragment>
        );
}
export default NotFount;
